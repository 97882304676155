/* Contenedor Principal */
.profile-settings-container {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }
  
  /* Tarjeta de Perfil */
  .profile-card {
    background: none;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Avatar */
  .profile-avatar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto 15px;
  }
  
  .profile-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    /* border: 4px solid #ddd; */
  }
  
  /* Canvas para redondear imagen */
  .profile-avatar-wrapper canvas {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }
  
  /* Ícono Placeholder si no hay imagen */
  .profile-placeholder-icon {
    color: #bbb;
    width: 100px;
    height: 100px;
  }
  
  /* Información del Usuario */
  .profile-info-wrapper {
    margin-bottom: 15px;
  }
  
  .profile-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .profile-role {
    font-size: 14px;
    color: #ffffff;
  }
  
  /* Acciones (Botones) */
  .profile-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .upload-button, .delete-button {
    background: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s ease;
  }
  
  .upload-button:hover {
    background: #0056b3;
  }
  
  .delete-button {
    background: #dc3545;
  }
  
  .delete-button:hover {
    background: #a71d2a;
  }
  
  /* Descripción */
  .profile-description {
    font-size: 14px;
    color: #ffffff;
    line-height: 1.4;
  }
  


  .settings-content {
  min-width: 600px;
  margin: auto;
  padding: 20px;
}

.settings-tab-menu {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.settings-tab-link {
  background: none;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}

.settings-tab-link.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.settings-tabs-content {
  display: flex;
  flex-direction: column;
}

/* Asegura que los inputs estén en dos columnas */
.settings-form-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.settings-form-singel-wrappper {
  display: flex;
  width: 100%;
  gap: 10px;
}

.settings-singel {
  flex: 1;
  min-width: calc(50% - 10px); /* Para que cada campo ocupe la mitad */
  display: flex;
  flex-direction: column;
}

.settings-form label {
  font-weight: bold;
  margin-bottom: 5px;
}

.settings-form input,
.settings-form select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Botón de guardar */
.settings-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 15px;
}

.settings-button:hover {
  background: #0056b3;
}

/* Notificaciones en fila */
.settings-checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.settings-checkbox-field {
  display: flex;
  align-items: center;
  gap: 5px;
}


/* Estilos para el campo de texto */

.settings-form {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: none; /* Sin fondo */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
  }
  
  .settings-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .form-group h6 {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff; /* Texto en blanco */
    margin: 0;
  }
  
  .edit-text {
    color: #ffcc00; /* Amarillo para resaltar edición */
    font-size: 12px;
    cursor: pointer;
  }
  
  .input-box {
    background: none; /* Sin fondo */
    padding: 10px;
    border: 1px solid #ffffff; /* Bordes blancos */
    border-radius: 5px;
    min-width: 200px;
    color: #ffffff; /* Texto en blanco */
  }
  
  .location-box {
    background: none; /* Sin fondo */
    padding: 15px;
    border: 1px solid #ffffff; /* Bordes blancos */
    border-radius: 5px;
    color: #ffffff; /* Texto en blanco */
  }
  
  .location-box p {
    margin: 5px 0;
    color: #ffffff; /* Texto en blanco */
  }
  
  .upgrade-btn {
    background: none; /* Sin fondo */
    color: #ffffff; /* Texto en blanco */
    padding: 10px 15px;
    border: 1px solid #ffcc00; /* Borde amarillo para destacar */
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .upgrade-box {
    background: none; /* Sin fondo */
    padding: 15px;
    border: 1px solid #ffffff; /* Bordes blancos */
    border-radius: 5px;
    text-align: center;
    color: #ffffff; /* Texto en blanco */
  }
  
  .upgrade-box h6 {
    font-size: 14px;
    font-weight: bold;
  }
  
  .upgrade-box p {
    font-size: 12px;
    color: #ffffff; /* Texto en blanco */
  }
  

  /* style password */

  .password-settings {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: none; /* Fondo transparente */
    border-radius: 8px;
    text-align: center;
  }
  
  .password-title {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff; /* Texto en blanco */
    margin-bottom: 15px;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .password-input-group {
    display: flex;
    flex-direction: column;
  }
  
  .password-input {
    background: none; /* Fondo transparente */
    padding: 10px;
    border: 1px solid #ffffff; /* Borde blanco */
    border-radius: 5px;
    color: #ffffff; /* Texto en blanco */
    outline: none;
  }
  
  .password-input::placeholder {
    color: rgba(255, 255, 255, 0.6); /* Color gris claro para el placeholder */
  }
  
  .password-button {
    background: none; /* Fondo transparente */
    color: #ffcc00; /* Color amarillo para destacar */
    padding: 10px 15px;
    border: 1px solid #ffcc00; /* Borde amarillo */
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .password-button:hover {
    background: rgba(255, 204, 0, 0.2); /* Resaltar botón al pasar el mouse */
  }

  /* 2fa style */

  .twofa-settings {
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: none; /* Fondo transparente */
    border-radius: 8px;
    text-align: center;
  }
  
  .twofa-title {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff; /* Texto en blanco */
    margin-bottom: 10px;
  }
  
  .twofa-description, .twofa-instructions {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8); /* Texto blanco con opacidad */
    margin-bottom: 10px;
  }
  
  .twofa-qr-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .twofa-qr {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    border: 2px solid #ffffff; /* Borde blanco */
  }
  
  /* Secret Key Container */
  .twofa-secret-container {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .twofa-secret-label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 5px;
  }
  
  .twofa-secret-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 10px;
    gap: 10px;
  }
  
  .twofa-secret {
    color: #ffffff;
    font-size: 14px;
    word-break: break-all;
  }
  
  .twofa-copy-button {
    background: none;
    color: #ffcc00; /* Amarillo */
    border: 1px solid #ffcc00;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .twofa-copy-button:hover {
    background: rgba(255, 204, 0, 0.2);
  }
  
  /* Input for 6-digit code */
  .twofa-input-group {
    display: flex;
    justify-content: center;
  }
  
  .twofa-input {
    background: none;
    padding: 10px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    outline: none;
  }
  
  .twofa-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
  
  /* Verify Button */
  .twofa-button {
    background: none;
    color: #ffcc00;
    padding: 10px 15px;
    border: 1px solid #ffcc00;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .twofa-button:hover {
    background: rgba(255, 204, 0, 0.2);
  }
  

  /*SESSION STYLE*/

  .sessions-settings {
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background: none; /* Fondo transparente */
    border-radius: 8px;
    text-align: center;
  }
  
  .sessions-title {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff; /* Texto en blanco */
    margin-bottom: 10px;
  }
  
  .sessions-description {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8); /* Texto con opacidad */
    margin-bottom: 15px;
  }
  
  .sessions-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .sessions-table th, .sessions-table td {
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Borde tenue */
    text-align: left;
    color: #ffffff;
  }
  
  .sessions-table th {
    background: rgba(255, 255, 255, 0.1); /* Fondo tenue */
    font-weight: bold;
  }
  
  .sessions-table tr:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .success-message {
    color: green;
    font-size: 14px;
    margin-top: 5px;
  }
  