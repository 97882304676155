/* General Styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e0e0e0; /* Texto en gris claro */
}

body {
  direction: ltr;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
}

/* Header */
header {
  background-color: #1f1f1f; /* Fondo más claro que el cuerpo */
  color: #e0e0e0;
  text-align: center;
  padding: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

/* Main Content */
main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

main p {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #cccccc; /* Gris medio */
}

/* Footer */
footer {
  background-color: #1f1f1f;
  color: #e0e0e0;
  text-align: center;
  padding: 10px;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.4);
}

/* Links */
a {
  color: #64b5f6; /* Azul claro */
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #90caf9; /* Azul más claro */
}

/* Code Block */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #1e1e1e;
  color: #e0e0e0;
  padding: 5px;
  border-radius: 4px;
}

/* Buttons */
button {
  background-color: #333333;
  color: #e0e0e0;
  border: 1px solid #444;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: #444444;
  color: #ffffff;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

::-webkit-scrollbar-track {
  background-color: #121212;
}

/* Responsive Design */
@media (max-width: 768px) {
  header, footer {
    padding: 15px;
  }

  main p {
    font-size: 1rem;
  }
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Alinea elementos verticalmente */
  justify-content: center;
  align-items: center;
  background-color: #000000; /* Fondo oscuro */
  color: #ffffff; /* Texto blanco */
  z-index: 1000;
  overflow: hidden; /* Asegura que no haya desplazamiento */
}

.loading-video {
  width: 50vh; /* Ocupa todo el ancho de la pantalla */
  height: auto; /* Mantiene la proporción del video */
  max-height: 100%; /* Evita que el video sea más alto que la pantalla */
  object-fit: cover; /* Ajusta el video para que ocupe el contenedor sin deformarse */
}

.spinner {
  margin-top: 20px; /* Espaciado entre el video y el spinner */
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Animación del spinner */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.language-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.language-dropdown {
  padding: 8px 12px; /* Aumenta el padding */
  font-size: 18px; /* Texto más grande */
  cursor: pointer;
  min-width: 160px; /* Asegura suficiente espacio para "Português" */
  border-radius: 5px; /* Bordes redondeados */
  border: 1px solid #ccc; /* Borde sutil */
}

.language-flag {
  width: 30px;
  height: 20px;
  border-radius: 5px;
  object-fit: cover;
}
