.deposit-options-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .deposit-options-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .deposit-options-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
    max-width: 800px;
  }
  
  
  
  .deposit-option-item:nth-child(2n+1) {
    margin-right: 0; /* Quita margen en la última fila impar */
  }
  
  
  
  
  
  
  



  .deposit-option-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(50% - 10px);
    max-width: 350px;
    background-color: #ffffff; /* Fondo blanco para contraste */
    border: 1px solid #e0e0e0; /* Borde ligero para definición */
    border-radius: 12px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    transition: transform 0.3s, box-shadow 0.3s;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
  }
  
  .deposit-option-item:hover {
    transform: scale(1.05); /* Pequeño aumento de tamaño al pasar el mouse */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más intensa */
    border: 1px solid #007bff; /* Cambia el borde al pasar el mouse */
  }
  
  .deposit-option-content {
    padding: 20px;
    background: linear-gradient(135deg, #007bff, #00d4ff); /* Gradiente atractivo */
    color: #ffffff; /* Texto blanco para contraste */
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  .deposit-option-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #111111; /* Título en blanco */
  }
  
  .deposit-option-description {
    font-size: 14px;
    color: #362341; /* Gris claro para el texto */
  }
  
  .deposit-option-footer {
    padding: 10px;
    text-align: center;
    font-size: 12px;
    background-color: #f1f1f1; /* Fondo gris claro para separación */
    color: #feffff; /* Texto gris oscuro */
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  

  .deposit-options-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribuye los elementos */
    width: 100%;
    max-width: 800px; /* Ajusta el ancho para que sea coherente con el contenido */
    margin-bottom: 20px;
    position: relative;
  }
  
  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    transition: background 0.3s ease;
    position: absolute;
    left: 0; /* Alinea la flecha a la izquierda */
  }
  
  .back-button:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .back-button svg {
    color: #ffffff;
  }
  
  .deposit-options-title {
    flex-grow: 1; /* Permite que el título se mantenga centrado */
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  