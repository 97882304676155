/* Footer.css */

/* General Styles */
.footer {
    /* background-color: #1a1a1a; */
    color: #ffffff;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    line-height: 1.6;
}

/* Contenedor principal */
.footer__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Columna Styles */
.footer__columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.footer__column {
    flex: 1;
    min-width: 250px;
    margin-bottom: 20px;
}

/* Títulos */
.footer__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #ffcc00;
}

/* Botones */
.footer__button {
    display: inline-block;
    padding: 10px 20px;
    margin: 5px 0;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease;
}

.button--main {
    background-color: #007bff;
    color: #ffffff;
}

.button--main:hover {
    background-color: #0056b3;
}

.button--secondary {
    background-color: transparent;
    color: #007bff;
    border: 1px solid #007bff;
}

.button--secondary:hover {
    background-color: #007bff;
    color: #ffffff;
}

/* Enlaces */
.footer__link {
    display: block;
    margin: 5px 0;
    text-decoration: none;
    color: #cccccc;
    transition: color 0.3s ease;
}

.footer__link:hover {
    color: #ffffff;
}

/* Redes Sociales */
.footer__social-media {
    text-align: center;
    margin-bottom: 20px;
}

.footer__social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.footer__social-link img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}

.footer__social-link:hover img {
    transform: scale(1.2);
}

/* Información legal */
.footer__legal {
    text-align: center;
    border-top: 1px solid #333333;
    padding-top: 20px;
}

.footer__legal-text {
    margin: 5px 0;
    color: #aaaaaa;
}

/* Responsividad */
@media (max-width: 768px) {
    .footer__columns {
        flex-direction: column;
        align-items: center;
    }

    .footer__column {
        text-align: center;
    }
}
